<div class="micro-parent py-10 min-h-screen md:h-full sm:h-full lg:min-h-screen xl:min-h-screen xs:h-full">
  <div class="sqa-micro">
    <h1 >SQAA Framework</h1>
    <p >
      SQAA is a set of standards and best practices as paradigms for attaining
      individual and institutional excellence. It is a tool for educationists,
      leaders, management and all those involved in making a difference in the
      lives of children.
    </p>
    <div class="card-wrapper">
      <div *ngFor="let card of cards">
        <app-card
          [title]="card.title"
          [description]="card.description"
          [imageSrc]="card.image"
          [buttonText]="card.buttonLabel"
          [link]="card.route"
          [blogData]="card.blogs"
        ></app-card>
      </div>
      <div *ngIf="!cards.length">
        No Cards Found ! 
      </div>
    </div>
  </div>
</div>
