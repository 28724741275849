<div class="main-content min-h-screen md:h-full sm:h-full lg:min-h-screen xl:min-h-screen xs:h-full">
    <div class="content">
        <h1 class="objective">
           <span>Objective:</span> {{objective}}
        </h1>
        <div class="desc-steps">
            <div class="description-content">
                <p>
                    <span>Description:</span> {{description}}
                </p>
                <div>
                    <span>Recommended duration:</span>
                    <p>
                        {{date}}
                    </p>
                </div>
                <div>
                    <span>Recommended for:</span>
                    <div class="flex gap-2 mt-3 flex-wrap">
                        <p *ngFor="let rec of tags" class="tag">
                            {{rec}}
                        </p>
                    </div>
                </div>
                <div>
                    <span>SQAA Domain:</span>
                    <p>
                        {{sqaDomain}}
                    </p>
                </div>
                <div>
                    <span>Sub- Domain:</span>
                    <p>
                        {{subCategory}}
                    </p>
                </div>
            
            </div>
            
            <ul class="steps-content">
                <h2>Steps Taken-</h2>
                <li class="list-steps" *ngFor="let step of stepsTaken; let indexOfelement=index;">
                    <span>{{indexOfelement + 1}}</span>
                    <p>
                        {{step}}
                    </p>
                </li>
            </ul>
        </div>
        <div *ngIf="isVideo" class="impact-story">
            <h2>Impact Story</h2>
            <p>Mrs XYZ (HM, ABC school, Bangalore)</p>
            <div >
                <iframe class="px-4" src="https://drive.google.com/file/d/1OPg2ZGOvk_udXhfqYu9CroBGlgD7zNkl/preview" 
                    title="Iframe Example"></iframe>
            </div>
            
        </div>

    </div>
</div>
