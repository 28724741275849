<!-- card.component.html -->

<div
  class="card"
  (click)="onCardClickHandler()"
  (mouseenter)="onCardHover()"
  (mouseleave)="onCardLeave()"
>
  <a class="cursor-pointer">
    <img class="rounded-t-lg" [src]="imageSrc" alt="" />
  </a>
  <div class="">
    <a class="card-title cursor-pointer">
      {{ title }}
    </a>
    <div *ngIf="active" class="flex flex-col gap-4">
      <!-- <a>
        <p class="card-description">{{ description }}</p>
      </a> -->
      <!-- button -->
      <a class="view-projects-btn cursor-pointer "> View Projects </a>
    </div>
  </div>
</div>
