<section
    class="hero-section w-full bg-cover bg-contain  md:bg-contain lg:bg-cover lg:bg-contain xl:bg-cover xl:bg-contain">
</section>
<section>
    <div class="flex gap-6 justify-center flex-wrap py-3">
        <div *ngFor="let st of stat"
            class=" transform  rounded-md h-40 w-40 sm:h-64 sm:w-64 bg-white shadow-lg transition duration-300 hover:scale-105 stat-card custom-box-shadow">
            <div class="flex h-full justify-center items-center flex-col">
                <p class="stat-number">
                    {{st.stat}}<span>{{st.decimalData}}</span> {{st.sign}}
                </p>
                <span class="stat-text">
                    {{st.desc}}
                </span>
            </div>
        </div>
    </div>
   
</section>