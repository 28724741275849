<!-- blog.component.html -->

<a class="blog cursor-pointer" (click)="onBlogItemClickHandler()">
  <div class="date">{{ date }}</div>
  <div class="title">{{ title }}</div>
<div class="description">{{ description.split(' ').length > 18 ? description.split(' ').slice(0, 18).join(' ') + '...' : description }}</div>
  <div class="tags">
    <ng-container *ngFor="let tag of tags">
      <span class="tag">{{ tag }}</span>
    </ng-container>
  </div>
</a>
