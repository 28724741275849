<section class="infra min-h-screen md:h-full sm:h-full lg:min-h-screen xl:min-h-screen xs:h-full">
  <div class="flex bar-options">
    <div class="search-options">
      <!-- search component -->
      <div class="max-w-2xl mx-auto search-bar ">
        <div
          class="relative flex items-center w-full rounded-full focus-within:shadow-lg bg-white overflow-hidden search-bar-h"
        >
          <div class="grid place-items-center h-full w-12 text-gray-300">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#223ea0"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </div>

          <input
            class="peer h-full w-full outline-none text-sm text-gray-700 pr-2"
            type="text"
            id="search"
            placeholder="Search projects.."
          />
        </div>
      </div>
    </div>
    <div class="filter-buttons flex">
      <app-dropdown-checkbox
        [text]="'Sort by'"
        [lists]="sortOptions"
        (optionSelected)="handleSortOptionSelected($event)"
        [selectedOption]="selectedSortOption"
      >
      </app-dropdown-checkbox>
              <!-- [selectedOption]="selectedSortOption" -->

      <app-dropdown-checkbox
        [text]="'Role'"
        [lists]="role"
        (optionSelected)="handleRoleSelected($event)"
        [selectedOption]="selectedRole"
      ></app-dropdown-checkbox>
    </div>
  </div>

  <div class="blog-section" *ngIf="blogs.length > 0">
    <div *ngFor="let blog of blogs; let idx = index">
      <app-blog
        [date]="blog.date"
        [title]="blog.title"
        [description]="blog.description"
        [tags]="blog.tags"
        [index]="idx"
        [blogData]="blogs"
      ></app-blog>
    </div>
  </div>
  <div *ngIf="blogs.length === 0" class=" blog-svg">
    <h1>No Results Found.</h1>
    <img class="w-96 h-96" src="../../../assets/images/empty.svg" />
  </div>
</section>
