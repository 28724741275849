<header *ngIf="isHomePage">
  <div class="h-9 mini-header px-12 py-3 flex items-center justify-between">
    <a class="flex items-center flex-wrap gap-2">
      <img src="assets/images/india-flag-icon.png" class="flag-img" />
      <a class="text-sm gov-text">Government of India</a>
    </a>
    <div>
      <ul class="flex items-center flex-wrap gap-2 text-xs list">
        <li>
          <i class="material-icons icon-gradient-blue me-1 align-middle">
            important_devices
          </i>
          <a>Main Content</a>
        </li>
        <li class="flex items-center gap-4">
          <a>A+</a>
          <a><span class="text-selected">A</span></a>
          <a>A-</a>
        </li>
        <li>
          <i class="material-icons icon-gradient-blue me-1 align-middle">
            chrome_reader_mode
          </i>
          <a>Screen Reader</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="flex items-center justify-between px-10 py-2 ministery">
    <div class="ministry-logo flex items-center gap-2">
      <img src="assets/images/gov.png" />
    </div>
    <div class="flex items-center gap-4">
      <div class="vidya-text-wrap">
        <h2 class="vidya-text">Vidya Amrit</h2>
      </div>
      <div class="left-nav">
        <p class="text-sm px-0">National Digital Education Architecture</p>
        <span class="text-xs px-0"
          >Digital Infrastructure for the education system</span
        >
      </div>
    </div>
  </div>

  <div>
    <div class="hidden sm:block">
      <nav class="h-12 p-3 nav px-8">
        <ul class="flex gap-3 flex-wrap items-center">
          <li class="px-4" *ngFor="let link of navLinks">
            <a class="text-sm text-white" [routerLink]="link.route">{{ link.label }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="sm:hidden mx-2 my-2 menu-new">
      <div (click)="toggleMenu()" class="cursor-pointer">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
    </div>
    
    <div *ngIf="isMenuOpen" class="menu h-screen">
      <i class="material-icons icon-gradient-blue me-1 align-middle text-white absolute	p-2 right-0" (click)="toggleMenu()">
        close
      </i>
      <ul class="flex flex-col gap-3 items-center">
        <li class="px-4" *ngFor="let link of navLinks">
          <a class="text-sm text-black" [routerLink]="link.route">{{ link.label }}</a>
        </li>
      </ul>
    </div>
  </div>
  <!-- <nav class="sm:h-12 sm:p-3 nav px-8">
    <ul class="flex gap-3 flex-wrap items-center">
      <li class="px-4" *ngFor="let link of navLinks">
        <a class="text-sm text-white" [routerLink]="link.route">{{
          link.label
        }}</a>
      </li>
    </ul>
  </nav> -->
</header>
<header class="sticky-header" *ngIf="!isHomePage">
  <div class="back-nav cursor-pointer">
    <div (click)="goBack()" class="flex gap-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35"
        height="33"
        viewBox="0 0 35 33"
        fill="none"
      >
        <path
          d="M16.625 31.25L2 16.625L16.625 2M4.03125 16.625H33.6875"
          stroke="#3D4A7D"
          stroke-width="2.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div *ngIf="showInfraHead" class="infra-texts capitalize">
          {{ clickedCardTitle }}
      </div>
    </div>

    <div class="flex items-center w-full ml-9 flex-wrap" *ngIf="isContentPage">
      <div class="flex gap-6 justify-center arr m-auto">
        <div (click)="prevNextClickHandler('prev')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="53"
            viewBox="0 0 52 53"
            fill="none"
          >
            <path
              d="M24.7812 41.1294L10.1562 26.5044L24.7812 11.8794"
              stroke="#004590"
              stroke-width="2.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div (click)="prevNextClickHandler('next')">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="52"
            height="53"
            viewBox="0 0 52 53"
            fill="none"
          >
            <path
              d="M27.2188 11.8706L41.8438 26.4956L27.2188 41.1206"
              stroke="#004590"
              stroke-width="2.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div>
        <button class="dwn-btn">
          <span>Download</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 35 35"
            fill="none"
          >
            <path
              d="M25.7031 10.9375H18.5938V21.4218L22.1956 17.8206C22.4024 17.6241 22.6777 17.5162 22.963 17.5199C23.2482 17.5235 23.5207 17.6385 23.7223 17.8402C23.924 18.0418 24.039 18.3143 24.0426 18.5995C24.0463 18.8848 23.9384 19.1601 23.7419 19.3669L18.2731 24.8356C18.068 25.0406 17.79 25.1557 17.5 25.1557C17.21 25.1557 16.932 25.0406 16.7269 24.8356L11.2581 19.3669C11.0616 19.1601 10.9537 18.8848 10.9574 18.5995C10.961 18.3143 11.076 18.0418 11.2777 17.8402C11.4793 17.6385 11.7518 17.5235 12.037 17.5199C12.3223 17.5162 12.5976 17.6241 12.8044 17.8206L16.4062 21.4218V10.9375H9.29688C8.28193 10.9386 7.30886 11.3423 6.59118 12.0599C5.8735 12.7776 5.46984 13.7507 5.46875 14.7656V28.9844C5.46984 29.9993 5.8735 30.9724 6.59118 31.6901C7.30886 32.4077 8.28193 32.8114 9.29688 32.8125H25.7031C26.7181 32.8114 27.6911 32.4077 28.4088 31.6901C29.1265 30.9724 29.5302 29.9993 29.5312 28.9844V14.7656C29.5302 13.7507 29.1265 12.7776 28.4088 12.0599C27.6911 11.3423 26.7181 10.9386 25.7031 10.9375Z"
              fill="white"
            />
            <path
              d="M18.5938 3.28125C18.5938 2.99117 18.4785 2.71297 18.2734 2.50785C18.0683 2.30273 17.7901 2.1875 17.5 2.1875C17.2099 2.1875 16.9317 2.30273 16.7266 2.50785C16.5215 2.71297 16.4062 2.99117 16.4062 3.28125V10.9375H18.5938V3.28125Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</header>
