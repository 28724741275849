<section>
  <div class="micro-parent">
    <app-content-page
      [objective]="exp.objective"
      [description]="exp.description"
      [tags]="exp.tags"
      [date]="exp.date"
      [sqaDomain]="exp.sqaDomain"
      [subCategory]="exp.subCategory"
      [stepsTaken]="exp.stepsTaken"
      [isVideo]="exp.isVideo"
    >
    </app-content-page>
  </div>
</section>
