<div class="micro-parent h-screen flex items-center justify-center ">
<div class="main-micro sm:px-4 md:px-8 lg:px-8">
      <h1 class="micro-title">Micro-Improvement Jadui Pitara</h1>
    <p class="micro-desc w-5/6"> 
      Leading positive outcomes by making small, contextual, and feasible
      changes
    </p>
    <!-- <button class="explore-btn">Explore</button> -->

    <a [routerLink]="'/micro-improvement'" class="explore-btn"> Explore </a>
  </div>
</div>
